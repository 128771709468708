export const useOrderStore = defineStore('orderStore', () => {
  const pending = ref<boolean>(false);
  const orders = ref<Array<any>>();
  const order = ref<any>();

  return {
    pending,
    orders,
    order,
  };
});
